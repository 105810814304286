<template>
  <div id="main-page">
    <my-header></my-header>
    <router-view></router-view>
    <my-footer></my-footer>
  </div>
</template>

<script>
import Header from '@/views/Header.vue'
import Footer from '@/views/Footer.vue'
export default {
  name: 'app',
  data(){
    return {
    }
  },
  props: {
  },
  components: {
    'my-header': Header,
    'my-footer': Footer
  }
}
</script>

<style lang="scss">
@import "@/assets/style/style.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
}
</style>
