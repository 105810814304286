import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Terms from '../views/Terms.vue'
import Politics from '../views/Politics.vue'
import Dispatch from '../views/Dispatch.vue'
import Faq from '../views/Faq.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main,
    children: [
      {path: '', name: 'home', component: Home, meta: {title: 'Oneplay Perú'}}
    ]
  },
  {
    path: '/terms',
    name: 'Términos y condiciones',
    component: Main,
    children: [
      {path: '', name: 'terms', component: Terms, meta:{title: 'Oneplay Perú | Términos y Condiciones'}}
    ]
  },
  {
    path: '/politics',
    name: 'Políticas de privacidad',
    component: Main,
    children: [
      {path: '', name: 'politics', component: Politics, meta:{title: 'Oneplay | Políticas de privacidad'}}
    ]
  },
  {
    path: '/dispatch',
    name: 'Políticas de despacho',
    component: Main,
    children: [
      {path: '', name: 'dispatch', component: Dispatch, meta:{title: 'Oneplay | Políticas de despacho'}}
    ]
  },
  {
    path: '/faq',
    name: 'Preguntas Frecuentes',
    component: Main,
    children: [
      {path: '', name: 'faq', component: Faq, meta:{title: 'Oneplay | Preguntas Frecuentes'}}
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0,0)
	document.title = to.meta.title
  next()
})

export default router
