<template>
    <b-container class="mt-lg-5">
        <b-row>
            <b-col class="mt-lg-5 mb-lg-5 pt-lg-4 pt-4 mb-4 mt-5">
                <div class="faq-title">Preguntas Frecuentes</div>
            </b-col>
        </b-row>
        <b-container>
            <b-row>
                <b-col>
                    <div class="faq-text-title"><b>DISPOSITIVOS APP Y TV MÓVIL</b></div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="faq-text mt-lg-5"><b>APP TV BOX</b></div>
                </b-col>
            </b-row>
            <b-container>
                <b-list-group>
                    <b-list-group-item class="mt-2">
                        <b-button block v-b-toggle.accordion-1 variant="link" class="btn-coll">
                            <span class="text-left">¿Cómo consigo mi TV BOX?</span>
                            <b-icon v-if="isVisible" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" v-model="isVisible">
                        <b-list-group-item class="coll-response notsosmall">
                            Lo puedes adquirir directamente en nuestra página web. Si requieres asistencia, contáctanos a nuestro WhatsApp de Asistencia Comercial publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-2 variant="link" class="btn-coll">
                            <span class="text-left">¿La instalación de mi TV BOX tiene costo asociado?</span>
                            <b-icon v-if="isVisible2" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" v-model="isVisible2">
                        <b-list-group-item class="coll-response notsosmall">
                            No, no tiene costo adicional, ni costo de mantención. Si requieres ayuda para la instalación, puedes contactarte al WhatsApp de Soporte Técnico publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-3 variant="link" class="btn-coll">
                            <span class="text-left">¿Puedo ver televisión en múltiples dispositivos a la vez?</span>
                            <b-icon v-if="isVisible3" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" v-model="isVisible3">
                        <b-list-group-item class="coll-response notsosmall">
                            Si, puedes ver en tu televisor y a la vez en tu App Móvil. Según el plan adquirido.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-4 variant="link" class="btn-coll">
                            <span class="text-left">¿Necesito tener un SMART TV?</span>
                            <b-icon v-if="isVisible4" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" v-model="isVisible4">
                        <b-list-group-item class="coll-response notsosmall">
                            No, no necesitas tener un Smart TV, puedes tener cualquier tipo de televisor que tenga una entrada HDMI.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-5 variant="link" class="btn-coll">
                            <span class="text-left">¿Cómo entro a la APP en mi televisor?</span>
                            <b-icon v-if="isVisible5" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" v-model="isVisible5">
                        <b-list-group-item class="coll-response notsosmall">
                            La aplicación para la televisión, viene cargada en la TV BOX que adquieres con tu plan prepago. Conectas tu TV BOX a tu televisor mediante un cable HDMI, luego enciendes el televisor y TV BOX y  quedas conectado, así de sencillo.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-6 variant="link" class="btn-coll">
                            <span class="text-left">¿Dónde encuentro los canales?</span>
                            <b-icon v-if="isVisible6" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" v-model="isVisible6">
                        <b-list-group-item class="coll-response notsosmall">
                            Entras a la aplicación en tu televisor y en la parte inferior de la pantalla, encontrarás varias categorías, entre ellas televisión abierta, misceláneos, deportes, musicales, entre otros.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-7 variant="link" class="btn-coll">
                            <span class="text-left">¿Cómo ingreso a mi cuenta?</span>
                            <b-icon v-if="isVisible7" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel" v-model="isVisible7">
                        <b-list-group-item class="coll-response notsosmall">
                            Cuando compras un plan prepago, debes ingresar tus datos personales en nuestro sitio web, en ese instante quedas automáticamente registrado. 
                        </b-list-group-item>
                    </b-collapse>
                </b-list-group>
            </b-container>

            <b-row>
                <b-col>
                    <div class="faq-text mt-lg-5"><b>SOPORTE</b></div>
                </b-col>
            </b-row>
            <b-container>
                <b-list-group>
                    <b-list-group-item class="mt-2">
                        <b-button block v-b-toggle.accordion-8 variant="link" class="btn-coll">
                            <span class="text-left">¿Por qué la TV BOX no funciona?</span>
                            <b-icon v-if="isVisible8" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel" v-model="isVisible8">
                        <b-list-group-item class="coll-response notsosmall">
                            La causa más común, es el poco ancho de banda que tienen algunos usuarios. 
                            El ancho de banda mínimo que se indica es de 5Mbps. Aunque nosotros recomendamos 
                            conectar la TV BOX a un cable de red de internet, así evitamos problemas de conexión y 
                            funcionamiento. Si el problema aun persiste con tu TV BOX, contáctanos a nuestro WhatsApp 
                            de Soporte Técnico publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-9 variant="link" class="btn-coll">
                            <span class="text-left">¿No se reproduce un canal, ¿Qué hago?</span>
                            <b-icon v-if="isVisible9" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel" v-model="isVisible9">
                        <b-list-group-item class="coll-response notsosmall">
                            Verificar si es el único canal que no funciona o si hay más canales en la misma situación y contactarnos a nuestro WhatsApp de Soporte Técnico publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-10 variant="link" class="btn-coll">
                            <span class="text-left">Tengo problemas con el audio</span>
                            <b-icon v-if="isVisible10" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel" v-model="isVisible10">
                        <b-list-group-item class="coll-response notsosmall">
                            Verificar el volumen de la TV BOX y también el volumen de tu televisor, si tienes otros equipos de sonido conectados, 
                            de igual forma chequearlos. Si el problema aún persiste con tu TV BOX, contáctanos a nuestro WhatsApp de Soporte Técnico publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-11 variant="link" class="btn-coll">
                            <span class="text-left">La instalación de mi TV BOX, ¿Puedo hacerla yo directamente o necesito un Técnico)</span>
                            <b-icon v-if="isVisible11" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel" v-model="isVisible11">
                        <b-list-group-item class="coll-response notsosmall">
                            La instalación de la TV BOX es plug and play (enchufar, conectar y usar), por lo que no requiere de ayuda técnica. 
                            Si tienes problemas para la instalación de tu TV BOX, contáctanos a nuestro WhatsApp de Soporte Técnico publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-12 variant="link" class="btn-coll">
                            <span class="text-left">Olvide mi contraseña ¿Qué debo hacer?</span>
                            <b-icon v-if="isVisible12" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel" v-model="isVisible12">
                        <b-list-group-item class="coll-response notsosmall">
                            Para reestablecer tu contraseña, debes comunicarte al WhatsApp de Soporte Técnico publicado en nuestro sitio web.
                        </b-list-group-item>
                    </b-collapse>
                </b-list-group>
            </b-container>
            <b-row>
                <b-col>
                    <div class="faq-text mt-lg-5"><b>COMERCIAL Y VENTAS</b></div>
                </b-col>
            </b-row>
            <b-container>
                <b-list-group>
                    <b-list-group-item class="mt-2">
                        <b-button block v-b-toggle.accordion-13 variant="link" class="btn-coll">
                            <span class="text-left">¿Desde qué momento empieza a correr el mes gratis?</span>
                            <b-icon v-if="isVisible13" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel" v-model="isVisible13">
                        <b-list-group-item class="coll-response notsosmall">
                            El mes gratis comienza a correr desde que adquieres nuestros servicios. Esto incluye el despacho de la TV BOX 
                            a domicilio, tiempo de entrega alrededor de 5 días. De esta manera tienes 35 días en tu primera compra. 
                            Tienes 30 días desde que  llega el TV BOX a tus manos.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-14 variant="link" class="btn-coll">
                            <span class="text-left">Si me cambio de plan, ¿Obtengo nuevamente el mes gratis?</span>
                            <b-icon v-if="isVisible14" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-14" accordion="my-accordion" role="tabpanel" v-model="isVisible14">
                        <b-list-group-item class="coll-response notsosmall">
                            No, el plan gratis es por única vez.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-15 variant="link" class="btn-coll">
                            <span class="text-left">¿Cómo doy de baja mi suscripción?</span>
                            <b-icon v-if="isVisible15" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel" v-model="isVisible15">
                        <b-list-group-item class="coll-response notsosmall">
                            Este es un Servicio Prepago, sólo dejas de cancelar nuestro servicio y listo. 
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-16 variant="link" class="btn-coll">
                            <span class="text-left">¿Cuáles son los métodos de pago?</span>
                            <b-icon v-if="isVisible16" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-16" accordion="my-accordion" role="tabpanel" v-model="isVisible16">
                        <b-list-group-item class="coll-response notsosmall">
                            Los métodos de pagos  disponibles en nuestro sitio web es a través de  Webpay, tarjetas de crédito y tarjetas de débito.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-17 variant="link" class="btn-coll">
                            <span class="text-left">¿Dónde puedo revisar el detalle de mi compra?</span>
                            <b-icon v-if="isVisible17" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-17" accordion="my-accordion" role="tabpanel" v-model="isVisible17">
                        <b-list-group-item class="coll-response notsosmall">
                            Luego de contratar el Servicio, enviaremos tu boleta de compra al correo electrónico registrado.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-18 variant="link" class="btn-coll">
                            <span class="text-left">¿Dónde y cuándo renuevo mi plan?</span>
                            <b-icon v-if="isVisible18" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-18" accordion="my-accordion" role="tabpanel" v-model="isVisible18">
                        <b-list-group-item class="coll-response notsosmall">
                            En nuestro sitio web, ingresando con tu usuario y contraseña, ingresas directamente a tu cuenta. Al 
                            término de los 30 días, debes cancelar la renovación del servicio, para seguir disfrutando de la mejor 
                            forma de ver televisión por los próximos 30 días.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-19 variant="link" class="btn-coll">
                            <span class="text-left">¿Qué canales tienen?</span>
                            <b-icon v-if="isVisible19" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-19" accordion="my-accordion" role="tabpanel" v-model="isVisible19">
                        <b-list-group-item class="coll-response notsosmall">
                            Contamos con más de 80 canales. Entre ellos, canales nacionales y regionales.  
                            Además de una selección de canales de entretención y cine.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-20 variant="link" class="btn-coll">
                            <span class="text-left">¿Existe límite geográﬁco para usar el servicio?</span>
                            <b-icon v-if="isVisible20" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-20" accordion="my-accordion" role="tabpanel" v-model="isVisible20">
                        <b-list-group-item class="coll-response notsosmall">
                            Se limita al territorio nacional.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-21 variant="link" class="btn-coll">
                            <span class="text-left">¿Es legal?</span>
                            <b-icon v-if="isVisible21" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-21" accordion="my-accordion" role="tabpanel" v-model="isVisible21">
                        <b-list-group-item class="coll-response notsosmall">
                            Si. Contamos con los permisos y acuerdos, para la  comercialización de señales.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-22 variant="link" class="btn-coll">
                            <span class="text-left">¿Pueden 2 familias comprar el plan, compartirlo y  pagarlo a medias?</span>
                            <b-icon v-if="isVisible22" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-22" accordion="my-accordion" role="tabpanel" v-model="isVisible22">
                        <b-list-group-item class="coll-response notsosmall">
                            Si, pueden. El plan lo puedes compartir con quienes desees, ahorras dinero, y compartes con tus amigos y familia, eso lo decides tú.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-23 variant="link" class="btn-coll">
                            <span class="text-left">¿Cómo se despachan los TV BOX a los usuarios?</span>
                            <b-icon v-if="isVisible23" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-23" accordion="my-accordion" role="tabpanel" v-model="isVisible23">
                        <b-list-group-item class="coll-response notsosmall">
                            Se despachan a todo Chile vía Correos de Chile.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-24 variant="link" class="btn-coll">
                            <span class="text-left">Para contratar un plan, ¿Qué requisitos necesito?</span>
                            <b-icon v-if="isVisible24" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-24" accordion="my-accordion" role="tabpanel" v-model="isVisible24">
                        <b-list-group-item class="coll-response notsosmall">
                            No se considera una contratación de plan, ya que funciona  como prepago. Si no puedes o no quieres renovar el  
                            servicio, no se activa. Sólo se vuelve a activar, cuando ingresas a tu sesión en nuestro sitio web y generas 
                            el pago del plan que deseas disfrutar por los próximos 30 días.
                        </b-list-group-item>
                    </b-collapse>
                </b-list-group>
            </b-container>
            <b-row>
                <b-col>
                    <div class="faq-text mt-lg-5"><b>APP MÓVIL</b></div>
                </b-col>
            </b-row>
            <b-container>
                <b-list-group>
                    <b-list-group-item class="mt-2">
                        <b-button block v-b-toggle.accordion-25 variant="link" class="btn-coll">
                            <span class="text-left">¿Dónde puedo descargar la App Móvil?</span>
                            <b-icon v-if="isVisible25" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-25" accordion="my-accordion" role="tabpanel" v-model="isVisible25">
                        <b-list-group-item class="coll-response notsosmall">
                            Puedes descargar nuestra App Móvil desde Playstore.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-26 variant="link" class="btn-coll">
                            <span class="text-left">¿Qué puedo hacer en la App Móvil?</span>
                            <b-icon v-if="isVisible26" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-26" accordion="my-accordion" role="tabpanel" v-model="isVisible26">
                        <b-list-group-item class="coll-response notsosmall">
                            Desde nuestra aplicación móvil puedes ver múltiples contenidos, como canales deportivos, 
                            canales nacionales, canales cristianos, canales de radios, canales de música, etc.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-27 variant="link" class="btn-coll">
                            <span class="text-left">¿En qué versión de Android funciona?</span>
                            <b-icon v-if="isVisible27" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-27" accordion="my-accordion" role="tabpanel" v-model="isVisible27">
                        <b-list-group-item class="coll-response notsosmall">
                            La aplicación móvil funciona en teléfonos Android, desde  la versión 7 en adelante.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-28 variant="link" class="btn-coll">
                            <span class="text-left">¿Tiene cobro adicional por descargar la App Móvil si adquirí mi plan con TV BOX?</span>
                            <b-icon v-if="isVisible28" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-28" accordion="my-accordion" role="tabpanel" v-model="isVisible28">
                        <b-list-group-item class="coll-response notsosmall">
                            No existe cobro adicional, el plan con TV BOX, contempla la descarga de la App Móvil.
                        </b-list-group-item>
                    </b-collapse>
                    <b-list-group-item>
                        <b-button block v-b-toggle.accordion-29 variant="link" class="btn-coll">
                            <span class="text-left">¿Puedo adquirir sólo App Móvil sin TV BOX?</span>
                            <b-icon v-if="isVisible29" aria-hidden="true" class="when-closed i-chev ml-auto" icon="chevron-up"></b-icon>
                            <b-icon v-else aria-hidden="true" class="when-opened i-chev ml-auto" icon="chevron-down"></b-icon>
                        </b-button>
                    </b-list-group-item>
                    <b-collapse id="accordion-29" accordion="my-accordion" role="tabpanel" v-model="isVisible29">
                        <b-list-group-item class="coll-response notsosmall">
                            Si, tienes la opción de adquirir sólo la App Móvil.
                        </b-list-group-item>
                    </b-collapse>
                </b-list-group>
            </b-container>
        </b-container>
    </b-container>
</template>
<script>
export default {
    data(){
        return{
            isVisible : false,
            isVisible2 : false,
            isVisible3 : false,
            isVisible4 : false,
            isVisible5 : false,
            isVisible6 : false,
            isVisible7 : false,
            isVisible8 : false,
            isVisible9 : false,
            isVisible10 : false,
            isVisible11 : false,
            isVisible12 : false,
            isVisible13 : false,
            isVisible14 : false,
            isVisible15 : false,
            isVisible16 : false,
            isVisible17 : false,
            isVisible18 : false,
            isVisible19 : false,
            isVisible20 : false,
            isVisible21 : false,
            isVisible22 : false,
            isVisible23 : false,
            isVisible24 : false,
            isVisible25 : false,
            isVisible26 : false,
            isVisible27 : false,
            isVisible28 : false,
            isVisible29 : false,
        }
    }
}
</script>
<style>
.i-chev{
    float: right;
}
.coll-response{
    color: gray !important;
    text-align: left;
}
.btn-coll{
    text-align: left !important;
    text-decoration: none !important;
}
.btn-link{
    color: #000 !important;
}
#faq-nav{
    background-color: rgb(0,0,0) !important;
    background-color: rgba(0,0,0, 1) !important;
    color: white;
    transition: background-color 200ms linear;
}
.faq-title{
    font-size: 1.8rem;
    font-family: 'Ceraround-pro', sans-serif;
    color: black;
}
.faq-text{
    font-family: 'Ceraround-pro', sans-serif;
    text-align: justify !important;
    font-size: .8rem;
    color: black;
}
.faq-text-title{
    font-family: 'Ceraround-pro', sans-serif;
    text-align: left !important;
    font-size: 1rem;
    color: black;
}
@media (min-width: 768px) {
    #faq-nav{
        background-color: rgb(0,0,0) !important;
        background-color: rgba(0,0,0, 1) !important;
        color: white;
        transition: background-color 200ms linear;
    }
    .faq-title{
        color: black;
        font-size: 2rem;
        font-family: 'Ceraround-pro', sans-serif;
    }
    .faq-text{
        font-family: 'Ceraround-pro', sans-serif;
        text-align: justify !important;
        font-size: .9rem;
        color: black;
    }
    .faq-text-title{
        font-family: 'Ceraround-pro', sans-serif;
        text-align: left !important;
        font-size: 1rem;
        color: black;
    }
}
</style>