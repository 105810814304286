<template>
  <div :class="this.routesHideHeader.includes(this.$route.name) ? '' : 'header'" id="header">
    <b-navbar toggleable="lg" sticky fixed type="dark" variant="info"
      :id="this.routesHideHeader.includes(this.$route.name) ? 'terms-nav' : 'main-nav'" style="z-index:1000"
      :class="{ 'navbarOpen': show }">
      <b-navbar-brand class="ml-lg-5" href="/#header"><img src="@/assets/logo.png" alt="OnePlay"
          @click="handleNavClick"></b-navbar-brand>
      <b-navbar-nav class="mobile-only mt-0">
      </b-navbar-nav>
      <b-navbar-toggle target="nav-collapse" :ref="'nav-toggler'" @click="toggleNavbar"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/#planes" class="text-nav ml-1" @click="handleNavClick">Planes</b-nav-item>
          <b-nav-item href="/#contenidos" class="text-nav ml-1" @click="handleNavClick">Contenidos</b-nav-item>
          <b-nav-item class="mobile-only text-nav mb-0"><b-button class="btn-green mobileMenuButton" @click="verifyToken">Oneplay Content Store</b-button></b-nav-item>
          <b-nav-item class=" text-nav mb-0 "><b-button class="btn-green mobileMenuButton" id="help-quest" v-b-modal.helpmodal style="font-size: 1rem !important;">¿Necesitas
            ayuda?</b-button></b-nav-item>
          <!--<b-nav-item href="#" class="text-nav ml-4" @click="handleNavClick">¿Cómo funciona?</b-nav-item>-->
          <b-nav-item class="mobile-only ml-1" v-if="!isAuthenticated">
            <b-button class="btn-login mobileMenuButton" v-b-modal.localloginmodal>Iniciar Sesión</b-button>
          </b-nav-item>
          <b-nav-item v-else>
            <b-dropdown right class="btnUserState btnUserStateMobile mobile-only">
              <template v-slot:button-content>
                <b-button class="btn-login mobileMenuButton">Hola, {{ firstName }}</b-button>
              </template>
              <b-dropdown-item @click="goToUserProfile">Mi Perfil</b-dropdown-item>
              <b-dropdown-item @click="logout">Cerrar Sesión</b-dropdown-item>
            </b-dropdown>
          </b-nav-item>
          <!-- <b-nav-item class="mobile-only ml-4">
              <b-button class="btn-sale" @click="handleButtonSale()"><b>Obtén tu MES GRATIS</b></b-button> 
            </b-nav-item> -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto desktop-only navBarDesk">
          <b-nav-item><b-button class="btn-green" @click="verifyToken">Oneplay Content Store</b-button></b-nav-item>
          <!-- <b-nav-item><b-button class="btn-green" v-b-modal.helpmodal>¿Necesitas ayuda en tu
              compra?</b-button></b-nav-item> -->
        </b-navbar-nav>
        <!-- <b-navbar-nav class="ml-2 desktop-only">
            <b-nav-item><b-button class="btn-sale" @click="handleButtonSale()"><b>Obtén tu MES GRATIS</b></b-button></b-nav-item>
          </b-navbar-nav> -->
        <b-navbar-nav class="desktop-only">
          <b-nav-item v-if="!isAuthenticated">
            <b-button class="btn-login" v-b-modal.localloginmodal>Iniciar Sesión</b-button>
          </b-nav-item>
          <b-nav-item v-else>
            <b-dropdown right class="btnUserState">
              <template v-slot:button-content>
                <b-button class="btn-login">Hola, {{ firstName }}</b-button>
              </template>
              <b-dropdown-item @click="goToUserProfile">Mi Perfil</b-dropdown-item>
              <b-dropdown-item @click="logout">Cerrar Sesión</b-dropdown-item>
            </b-dropdown>
          </b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <b-col cols="12" class="sec-1 pt-custom" :class="this.routesHideHeader.includes(this.$route.name) ? 'd-none' : ''">
      <b-row class="mx-lg-5 mt-lg-0">
        <b-col lg="7" sm="12">
          <div class="img-tv-title" id="image-space">
            <p><br></p>
          </div>
          <div class="img-tv mx-auto mt-lg-2">

          </div>
        </b-col>
        <b-col lg="5" sm="12" class="mt-sm-4 pt-lg-3" style="text-align:left">
          <div class="text-sec-1 mx-auto">
            <b class="title-bold">IPTV Prepago, <br> Vive la experiencia a un click</b> <br><br> Desde
            tu STB o App Móvil Controla <br> lo que gastas y paga cuando <br> quieras.
            <div class="spacer">&nbsp;</div>
          </div>
          <div class="mx-auto text-movil">
            <p>Disfruta de los mejores <b>contenidos <br> digitales </b> en múltiples dispositivos</p>
          </div>
          <div class="text-sec-1-mini mt-sm-0">
            <b><span class="promo-text plans">Conoce nuestros planes prepago desde<br></span></b>
            <b><span class="promo-text cost"><b style="font-size: 9vh;">S/25.00</b>Mensual</span></b>
          </div>
          <div class="mobile-only mt-4">
            <button class="onp-btn-b" @click="handleButtonPlans">VER PLANES</button>
          </div>
          <div class="desktop-only mt-sm-4">
            <button class="onp-btn-b" @click="handleButtonPlans">VER PLANES</button>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-modal id="helpmodal" size="lg" @hidden="resetModal" hide-footer no-close-on-backdrop no-close-on-esc>
      <transition name="fade" mode="out-in">
        <div v-if="modalstep">
          <p class="text-center">
            <img src="~@/assets/question-mark.png" alt="">
          </p>
          <p class="text-center h4">
            Deja tus datos acá y te contactaremos a la brevedad
          </p>
          <div class="text-center">
            <b-form @submit.prevent="sendHelp" class="mt-5 text-left" ref="formhelp" autocomplete="off">
              <b-form-row>
                <b-col md="7" lg="6" offset-lg="1" class="pr-md-4">
                  <label for="nomape">Nombre Apellido</label>
                  <b-form-input id="nomape" required name="name" placeholder="Ej: María Lira" pattern="([^\s][\D\s]+)"
                    oninvalid="setCustomValidity('Ingrese un nombre válido')"
                    oninput="(function(e){e.setCustomValidity(''); return !e.validity.valid && e.setCustomValidity(' ')})(this)"
                    minlength="5"></b-form-input>
                </b-col>
                <b-col md="5" lg="4" class="mt-4 mt-md-0">
                  <label for="fono">Teléfono</label>
                  <b-input-group prepend="+51">
                    <b-form-input id="fono" type="number" required name="telephone" placeholder="Ej: 987677897"
                      min="900000000" max="999999999" oninvalid="setCustomValidity('Ingrese un número celular válido')"
                      oninput="(function(e){e.setCustomValidity(''); return !e.validity.valid && e.setCustomValidity(' ')})(this)"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-form-row>
              <br>
              <div class="form-group mt-5 text-center">
                <b-overlay :show="sendingMail" rounded opacity="0.6" spinner-small class="d-inline-block">
                  <b-button type="submit" class="btn-lightblue px-5" :disabled="sendingMail">Enviar</b-button>
                </b-overlay>
              </div>
            </b-form>
          </div>
        </div>
        <div v-else class="text-center">
          <p>
            <img src="~@/assets/smile.png" alt="">
          </p>
          <p class="h4">
            Gracias por preferirnos, te contactaremos en breve
          </p>
          <br>
          <p class="mt-5">
            <b-button class="btn-lightblue px-5" @click="$bvModal.hide('helpmodal')">Cerrar</b-button>
          </p>
        </div>
      </transition>
    </b-modal>
    <b-modal id="loginmodal" size="lg" title="Iniciar Sesión" hide-footer class="modal-center">
      <template v-slot:modal-header>
        <h5 class="modal-title">Iniciar Sesión</h5>
        <b-button variant="close" @click="$bvModal.hide('loginmodal')"></b-button>
      </template>

      <b-form @submit.prevent="handleLogin" ref="loginForm" autocomplete="off">
        <b-form-group label="Correo Electrónico" label-for="email">
          <b-form-input id="email" type="email" v-model="loginData.email" required
            placeholder="Ingrese su correo electrónico"></b-form-input>
        </b-form-group>

        <b-form-group label="Contraseña" label-for="password">
          <b-form-input id="password" type="password" v-model="loginData.password" required
            placeholder="Ingrese su contraseña"></b-form-input>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="secondary" @click="$bvModal.hide('loginmodal')">Cancelar</b-button>
          <b-button variant="primary" type="submit" class="ml-2 loginModalBtn" :disabled="loggingIn">
            {{ loggingIn ? 'Iniciando...' : 'Iniciar Sesión' }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal id="localloginmodal" size="lg" title="Iniciar Sesión" hide-footer class="modal-center">
      <template v-slot:modal-header>
        <h5 class="modal-title">Iniciar Sesión</h5>
        <b-button variant="close" @click="$bvModal.hide('localloginmodal')"></b-button>
      </template>

      <b-form @submit.prevent="handleLocalLogin" ref="localloginForm" autocomplete="off">
        <b-form-group label="Correo Electrónico" label-for="email">
          <b-form-input id="email" type="email" v-model="loginData.email" required
            placeholder="Ingrese su correo electrónico"></b-form-input>
        </b-form-group>

        <b-form-group label="Contraseña" label-for="password">
          <b-form-input id="password" type="password" v-model="loginData.password" required
            placeholder="Ingrese su contraseña"></b-form-input>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="secondary" @click="$bvModal.hide('localloginmodal')">Cancelar</b-button>
          <b-button variant="primary" type="submit" class="ml-2 loginModalBtn" :disabled="loggingIn">
            {{ loggingIn ? 'Iniciando...' : 'Iniciar Sesión' }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.modal-center .modal-dialog {
  display: flex;
  align-items: center;
  /* Centrado vertical */
  justify-content: center;
  /* Centrado horizontal */
  margin: 0;
  /* Eliminar márgenes predeterminados */
  min-height: 100vh;
  /* Asegura que el modal se centre en la pantalla completa */
}

#loginmodal .modal-dialog,
#localloginmodal .modal-dialog {
  position: relative !important;
  margin: 1.75rem auto !important;
}

#loginmodal .modal-body,
#localloginmodal .modal-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;

}

.loginModalBtn {
  background-color: #08B051 !important;
  border: none !important;
}
.btnUserState .btn-secondary.dropdown-toggle, .btnUserState .show > .btn-secondary.dropdown-toggle{
  background-color: transparent!important;
  border:none;
}
.btnUserState .btn-secondary:focus,.btnUserState .btn-secondary.focus{
  box-shadow: none;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: #08B051 !important;
}
.btnUserStateMobile{
  flex-direction: column;
}
@media (min-width: 992px) {
  .navBarDesk {
    display: flex !important;
  }
}
@media (max-width:780px) {
  .mobileMenuButton{
    width: 100%;
  }
}

</style>
<script>
export default {
  data() {
    return {
      show: false,
      modalstep: true,
      sendingMail: false,
      routesHideHeader: ['terms', 'politics', 'dispatch', 'about', 'faq'],
      currentUser: null,
      isAuthenticated: false,
      loginData: {
        email: '',
        password: ''
      },
      loggingIn: false
    }
  },
  computed: {
    firstName() {
      if (this.currentUser && this.currentUser.name) {
        return this.currentUser.name.split(' ')[0];
      }
      return '';
    }
  },
  created() {
    const token = localStorage.getItem('authToken');
    const user = localStorage.getItem('authUser');
    if (token && user) {
      this.isAuthenticated = true;
      this.currentUser = JSON.parse(user);
    }
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
    },
    handleNavClick() {
      /* eslint-disable */
      console.log(this.$refs['nav-toggler'].$el.classList.contains('not-collapsed'));
      /* eslint-enable */
      const toggler = this.$refs['nav-toggler'].$el;
      if (toggler.classList.contains('not-collapsed')) {
        setTimeout(() => {
          toggler.click();
        }, 200);
      }
    },
    handleButtonSale() {
      window.location.href = 'https://pago.redcristianadigital.com/index.php?route=product/category&path=59';
    },
    handleButtonLogin() {
      // window.location.href = 'https://pago.redcristianadigital.com/index.php?route=account/login';
    },
    handleButtonPlans() {
      window.location.href = 'https://pago.redcristianadigital.com/index.php?route=product/category&path=59';
    },
    resetModal() {
      this.modalstep = true;
    },
    async sendHelp() {

      this.sendingMail = true;

      const formData = new FormData(this.$refs['formhelp']);
      const response = await fetch('https://iptvpago.redcristianadigital.com/api/v1/help-mail', { method: 'POST', body: formData });

      this.sendingMail = false;

      if (!response.ok) {
        alert("Error en el envío de la información, contacte al administrador");
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }

      const result = await response.json();

      ('error' in result && !result.error) ? this.modalstep = false : alert("Error en el envío de la información, contacte al administrador");
    },
    async handleLogin() {
      this.loggingIn = true;
      try {
        // 1. Solicitud de inicio de sesión para obtener el token
        const loginResponse = await fetch('https://oneplaybackend.cd-latam.com/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.loginData)
        });

        // Verificar si la solicitud fue exitosa
        if (!loginResponse.ok) {
          throw new Error('Error en el inicio de sesión');
        }

        // Obtener el resultado del inicio de sesión
        const loginResult = await loginResponse.json();
        console.log("el login 1 respondio ");
        console.log(loginResult);
        // 2. Usar el token para hacer una segunda solicitud a otra API
        // Verificar si la respuesta contiene un token

        if (loginResult.token) {
          const token = loginResult.token;
          const user = loginResult.user;
          localStorage.setItem('authToken', token);
          localStorage.setItem('authUser', JSON.stringify(user));
          console.log("el login 1 fue exitoso con token " + token);

          // 2. Usar el token para hacer una segunda solicitud a otra API
          const apiResponse = await fetch('https://oneplaybackend.cd-latam.com/api/open-kairos', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });

          // Verificar si la solicitud fue exitosa
          if (!apiResponse.ok) {
            throw new Error('Error al obtener el enlace de redirección');
          }

          // Obtener el resultado de la segunda solicitud
          const apiResult = await apiResponse.json();

          // 3. Verificar si la respuesta contiene un enlace y redirigir al usuario
          if (apiResult.data) {
            window.location.href = apiResult.data;
          } else {
            // alert('No se pudo obtener el enlace de redirección');
          }

          // Ocultar el modal si todo fue exitoso
          this.$bvModal.hide('loginmodal');

        } else {
          // alert('Credenciales inválidas o error en el inicio de sesión');
        }
      } catch (error) {
        console.error(error);
        // alert('Ocurrió un error al intentar iniciar sesión');
      } finally {
        this.loggingIn = false;
      }
    },
    async handleLocalLogin() {
      this.loggingIn = true; // Indicador de carga
      try {
        // 1. Realizar la solicitud de inicio de sesión para obtener el token
        const loginResponse = await fetch('https://oneplaybackend.cd-latam.com/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.loginData)
        });

        // Verificar si la solicitud fue exitosa
        if (!loginResponse.ok) {
          throw new Error('Error en el inicio de sesión');
        }

        // Obtener el resultado del inicio de sesión
        const loginResult = await loginResponse.json();

        // 2. Verificar si la respuesta contiene un token
        if (loginResult.token) {
          const token = loginResult.token;
          const user = loginResult.user;
          // 3. Guardar el token y el usuario en localStorage
          localStorage.setItem('authToken', token);
          localStorage.setItem('authUser', JSON.stringify(user));

          // Actualizar el estado de autenticación
          this.isAuthenticated = true;
          this.currentUser = user;

          // 4. Cerrar el modal de inicio de sesión
          this.$bvModal.hide('localloginmodal');
        } else {
          alert('Credenciales inválidas o error en el inicio de sesión');
        }
      } catch (error) {
        console.error(error);
        alert('Ocurrió un error al intentar iniciar sesión');
      } finally {
        this.loggingIn = false; // Desactivar el indicador de carga
      }
    },
    async verifyToken() {
      const token = localStorage.getItem('authToken');
      if (token) {
        // Token encontrado, procede con la acción
        console.log("Token válido: " + token);
        // Aquí puedes llamar a la API de validación o proceder con la acción deseada
        const apiResponse = await fetch('https://oneplaybackend.cd-latam.com/api/open-kairos', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        // Verificar si la solicitud fue exitosa
        if (!apiResponse.ok) {
          throw new Error('Error al obtener el enlace de redirección');
        }

        // Obtener el resultado de la segunda solicitud
        const apiResult = await apiResponse.json();

        // 3. Verificar si la respuesta contiene un enlace y redirigir al usuario
        if (apiResult.data) {
          window.location.href = apiResult.data;
        } else {
          // alert('No se pudo obtener el enlace de redirección');
        }

      } else {
        // Token no encontrado, mostrar el modal de inicio de sesión
        this.$bvModal.show('loginmodal');
      }
    },
    goToUserProfile() {
      // Redirige al perfil del usuario o realiza otra acción
      window.location.href = '/'; // Ajusta la ruta según tu aplicación
    },
    logout() {
      localStorage.removeItem('authToken');
      localStorage.removeItem('authUser');
      this.isAuthenticated = false;
      this.currentUser = null;
      window.location.href = '/'; // Redirige a la página principal
    }
  },
}
</script>